import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Input, PageHeader, Spin } from '~/core-components'
import { Col, DocumentTitle, Row } from '~/components'
import { StoreState } from '~/types/store'
import { useMyTenants } from '../../hooks'
import { MyTenantState } from '../../types'
import { TenantCard } from './TenantCard'
import './TenantSwitcherPage.less'

export const TenantSwitcherPage: FC = () => {
  const [myTenants, loading] = useMyTenants()
  const [adminTenants, setAdminTenants] = useState<MyTenantState[]>([])
  const [employeeTenants, setEmployeeTenants] = useState<MyTenantState[]>([])
  const myJwt = useSelector((state: StoreState) => state.iam.myJwt)
  const [search, setSearch] = useState('')
  const history = useHistory()

  useEffect(() => {
    const adminTenants = myTenants.filter(t => myJwt?.isAdmin?.includes(t.tenantCode))
    const employeeTenants = myTenants.filter(t => myJwt?.isEmployee?.includes(t.tenantCode))

    if (!search) {
      setAdminTenants(adminTenants)
      setEmployeeTenants(employeeTenants)
    } else {
      setAdminTenants(adminTenants.filter(t => t.tenantName.toLowerCase().includes(search.toLowerCase())))
      setEmployeeTenants(employeeTenants.filter(t => t.tenantName.toLowerCase().includes(search.toLowerCase())))
    }
  }, [search, myTenants, myJwt])

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }, [])

  const handleLogout = useCallback(() => {
    history.push('/logout')
  }, [])

  return (
    <div className="tenant-switcher-page">
      <DocumentTitle title="Workspaces" />
      <div className="tenant-switcher-page__container">
        <PageHeader title="Workspaces" subTitle="Switch between workspaces to manage different organizations." />
        <div className="tenant-switcher-page__body">
          <div className="tenant-switcher-page__action-bar">
            <Form.Item label="">
              <Input.Search placeholder="Search for workspace" style={{ width: 340 }} onChange={handleSearch} />
            </Form.Item>
            <div className="tenant-switcher-page__action-bar--right">
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          </div>
          {loading ? (
            <div>
              <Spin indicator={<LoadingOutlined spin />} />
            </div>
          ) : (
            <>
              {adminTenants.length > 0 && (
                <>
                  <div className="tenant-switcher-page__portal">Admin portal</div>
                  <Row gutter={[30, 15]} className="tenant-switcher-page__selection">
                    {adminTenants.map(t => (
                      <Col span={8} key={t.tenantCode}>
                        <TenantCard tenant={t} role="admin" />
                      </Col>
                    ))}
                  </Row>
                </>
              )}
              {employeeTenants.length > 0 && (
                <>
                  <div className="tenant-switcher-page__portal">Employee portal</div>
                  <Row gutter={[30, 15]} className="tenant-switcher-page__selection">
                    {employeeTenants.map(t => (
                      <Col span={8} key={t.tenantCode}>
                        <TenantCard tenant={t} role="employee" />
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
