import { RouteProps } from 'react-router-dom'
import { MenuItem } from '~/types/common'
import { Companies } from './containers/Company/Companies'
import { Company } from './containers/Company/Company'
import { Offices } from './containers/Office/Offices'
import { Office } from './containers/Office/Office'
import { Holidays } from './containers/Holiday/Holidays'
import { Holiday } from './containers/Holiday/Holiday'
import { Reasons } from './containers/Reason/Reasons'
import {
  CostCentres,
  EmploymentTypes,
  JobTitles,
  Departments,
  Teams,
  WorkCalendars,
  DocumentTypes,
  Races,
  Religions,
  LeaveGroups,
  Qualifications,
  Divisions,
  Sections,
  Grps,
  Categories,
  SalaryGrades
} from './containers/Master/Masters'
import { PayGroups } from './containers/PayGroup/PayGroups'
import { PayGroup } from './containers/PayGroup/PayGroup'
import { PayItemsSg } from './containers/PayItem/PayItemsSg'
import { PayItemSg } from './containers/PayItem/PayItemSg'
import { Banks } from './containers/Bank/Banks'
import { Formulas } from '~/features/formula/containers/Formulas'
import { LeaveTypes } from '../leave/containers/LeaveType/LeaveTypes'
import { LeaveType } from '../leave/containers/LeaveType/LeaveType'
import { ClaimTypes } from '../claim/containers/ClaimTypes/ClaimTypes'
import { ClaimType } from '../claim/containers/ClaimType/ClaimType'
import { EmailGroup } from './containers/EmailGroup/EmailGroup'
import { SecurityConfigs } from './containers/Configs/SecurityConfigs'
import { Logins } from '~/features/iam/containers/Login/Logins'
import { Login } from '~/features/iam/containers/Login/Login'
import { Permission } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Currencies } from '../claim/containers/Currency/Currencies'
import { CurrencyExchanges } from '../claim/containers/CurrencyExchange/CurrencyExchanges'
import { ClaApprovalWorkflows, LveApprovalWorkflows } from '../workflow/containers/Workflows'
import { ClaApprovalWorkflow, LveApprovalWorkflow } from '../workflow/containers/Workflow'
import { Locations } from '../attendance/containers/Locations/Locations'
import { Breaks } from '../attendance/containers/Breaks/Breaks'
import { ShiftCategories } from '../attendance/containers/ShiftCategories/ShiftCategories'
import { ShiftRoles } from '../attendance/containers/ShiftRoles/ShiftRoles'
import { ShiftRole } from '../attendance/containers/ShiftRole/ShiftRole'
import { Shifts } from '../attendance/containers/Shifts/Shifts'
import { Shift } from '../attendance/containers/Shift/Shift'
import { Calendars } from '../attendance/containers/Calendars/Calendars'
import { Calendar } from '../attendance/containers/Calendar/Calendar'
import { EmploymentConfigs } from './containers/Configs/EmploymentConfigs/EmploymentConfigs'
import { Xero } from '../payroll/containers/Xero/Xero'
import { XeroCallback } from '../payroll/containers/Xero/XeroCallback'
import { SelfUpdateConfigs } from './containers/Configs/SelfUpdateConfigs/SelfUpdateConfigs'
import { OtConfigs } from '../attendance/containers/OtConfigs/OtConfigs'
import { TeConfigs } from '../attendance/containers/TeConfigs/TeConfigs'
import { CalendarConfig } from './containers/Configs/CalendarConfig/CalendarConfig'
import { LveRecordCalendarViewers } from '../calendar-viewer/containers/CalendarViewers'
import { ExpenseTypes } from '../claim/containers/ExpenseTypes/ExpenseTypes'
import { LeaveBuddies } from '../leave/containers/LeaveBuddy/LeaveBuddies'
import { SsAccessConfigs } from './containers/Configs/SsAccessConfig/SsAccessConfigs'
import { DailyPolicy } from '../attendance/containers/DailyPolicy/DailyPolicy'
import { CfConfigs } from '../claim/containers/CfConfigs/CfConfigs'
import { Delegations } from './containers/Delegation/Delegations'

export const commonMenus: MenuItem[] = [
  {
    value: 'Companies',
    path: SETTINGS_ROUTES.companies,
    sysPermissionId: Permission.company
  },
  {
    value: 'Offices',
    path: SETTINGS_ROUTES.offices,
    sysPermissionId: Permission.master
  },
  {
    value: 'Holidays',
    path: SETTINGS_ROUTES.holidays,
    sysPermissionId: Permission.master
  },
  {
    value: 'Departments',
    path: SETTINGS_ROUTES.departments,
    sysPermissionId: Permission.master
  },
  {
    value: 'Divisions',
    path: SETTINGS_ROUTES.divisions,
    sysPermissionId: Permission.master
  },
  {
    value: 'Sections',
    path: SETTINGS_ROUTES.sections,
    sysPermissionId: Permission.master
  },
  {
    value: 'Groups',
    path: SETTINGS_ROUTES.grps,
    sysPermissionId: Permission.master
  },
  {
    value: 'Categories',
    path: SETTINGS_ROUTES.categories,
    sysPermissionId: Permission.master
  },
  {
    value: 'Teams',
    path: SETTINGS_ROUTES.teams,
    sysPermissionId: Permission.master
  },
  {
    value: 'Job titles',
    path: SETTINGS_ROUTES.jobs,
    sysPermissionId: Permission.master
  },
  {
    value: 'Cost centres',
    path: SETTINGS_ROUTES.costCentres,
    sysPermissionId: Permission.master
  },
  {
    value: 'Employment types',
    path: SETTINGS_ROUTES.employmentTypes,
    sysPermissionId: Permission.master
  },
  {
    value: 'Work calendars',
    path: SETTINGS_ROUTES.workCalendars,
    sysPermissionId: Permission.master
  },
  {
    value: 'Salary grades',
    path: SETTINGS_ROUTES.salaryGrades,
    sysPermissionId: Permission.master
  },
  {
    value: 'Document types',
    path: SETTINGS_ROUTES.docTypes,
    sysPermissionId: Permission.master
  },
  {
    value: 'Races',
    path: SETTINGS_ROUTES.races,
    sysPermissionId: Permission.master
  },
  {
    value: 'Religions',
    path: SETTINGS_ROUTES.religions,
    sysPermissionId: Permission.master
  },
  {
    value: 'Reasons',
    path: SETTINGS_ROUTES.reasons,
    sysPermissionId: Permission.master
  },
  {
    value: 'Banks',
    path: SETTINGS_ROUTES.banks,
    sysPermissionId: Permission.master
  },
  {
    value: 'Qualifications',
    path: SETTINGS_ROUTES.qualifications,
    sysPermissionId: Permission.master
  }
]

export const commonRoutes: RouteProps[] = [
  {
    path: SETTINGS_ROUTES.companies,
    component: Companies,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.company,
    component: Company,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.offices,
    component: Offices,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.office,
    component: Office,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.costCentres,
    component: CostCentres,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.employmentTypes,
    component: EmploymentTypes,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.jobs,
    component: JobTitles,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.holidays,
    component: Holidays,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.holiday,
    component: Holiday,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.departments,
    component: Departments,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.divisions,
    component: Divisions,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.sections,
    component: Sections,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.grps,
    component: Grps,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.categories,
    component: Categories,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.teams,
    component: Teams,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.workCalendars,
    component: WorkCalendars,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.salaryGrades,
    component: SalaryGrades,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.docTypes,
    component: DocumentTypes,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.races,
    component: Races,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.religions,
    component: Religions,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.reasons,
    component: Reasons,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.banks,
    component: Banks,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.qualifications,
    component: Qualifications,
    exact: true
  }
]

export const payrollMenus: MenuItem[] = [
  {
    value: 'Payroll groups',
    path: SETTINGS_ROUTES.payGroups,
    sysPermissionId: Permission.payMaster
  },
  {
    value: 'Payroll items',
    path: SETTINGS_ROUTES.payItemsSg,
    sysPermissionId: Permission.payMaster
  },
  {
    value: 'Formula',
    path: SETTINGS_ROUTES.formulas,
    sysPermissionId: [], //Permission.formula,
    internal: true
  }
]

export const payrollRoutes: RouteProps[] = [
  {
    path: SETTINGS_ROUTES.payGroups,
    component: PayGroups,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.payGroup,
    component: PayGroup,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.payItemsSg,
    component: PayItemsSg,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.payItemSg,
    component: PayItemSg,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.formulas,
    component: Formulas,
    exact: true
  }
]

export const leaveMenus: MenuItem[] = [
  {
    value: 'Leave groups',
    path: SETTINGS_ROUTES.leaveGroups,
    sysPermissionId: Permission.lveGroup
  },
  {
    value: 'Leave types',
    path: SETTINGS_ROUTES.leaveTypes,
    sysPermissionId: Permission.lveType
  },
  {
    value: 'Leave approval',
    path: SETTINGS_ROUTES.leaveApproval,
    sysPermissionId: Permission.lveApproval
  },
  {
    value: 'Leave buddy',
    path: SETTINGS_ROUTES.leaveBuddy,
    sysPermissionId: Permission.lveBuddy
  },
  {
    value: 'Organisation calendar',
    path: SETTINGS_ROUTES.calendarConfig,
    sysPermissionId: Permission.calendarConfig
  },
  {
    value: 'Leave calendar viewer',
    path: SETTINGS_ROUTES.lveRecordCalendarViewer,
    sysPermissionId: Permission.lveRecordCalendarViewer
  }
]

export const leaveRoutes: RouteProps[] = [
  {
    path: SETTINGS_ROUTES.leaveGroups,
    component: LeaveGroups,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.leaveTypes,
    component: LeaveTypes,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.leaveType,
    component: LeaveType,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.leaveApproval,
    component: LveApprovalWorkflows,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.leaveBuddy,
    component: LeaveBuddies,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.leaveApprovalTab,
    component: LveApprovalWorkflow,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.calendarConfig,
    component: CalendarConfig,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.lveRecordCalendarViewer,
    component: LveRecordCalendarViewers,
    exact: true
  }
]

export const claimMenus: MenuItem[] = [
  {
    value: 'Claim types',
    path: SETTINGS_ROUTES.claimTypes,
    sysPermissionId: Permission.claType
  },
  {
    value: 'Expense types',
    path: SETTINGS_ROUTES.expenseTypes,
    sysPermissionId: Permission.claType
  },
  {
    value: 'Custom fields',
    path: SETTINGS_ROUTES.cfConfigs,
    sysPermissionId: Permission.cfConfig
  },
  {
    value: 'Claim approval',
    path: SETTINGS_ROUTES.claimApproval,
    sysPermissionId: Permission.claApproval
  },
  {
    value: 'Currencies',
    path: SETTINGS_ROUTES.currencies,
    sysPermissionId: Permission.claCurrency
  },
  {
    value: 'Exchange rates',
    path: SETTINGS_ROUTES.currencyExchanges,
    sysPermissionId: Permission.claCurrency
  }
]

export const claimRoutes: RouteProps[] = [
  {
    path: SETTINGS_ROUTES.claimTypes,
    component: ClaimTypes,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.expenseTypes,
    component: ExpenseTypes,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.claimType,
    component: ClaimType,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.cfConfigs,
    component: CfConfigs,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.claimApproval,
    component: ClaApprovalWorkflows,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.claimApprovalTab,
    component: ClaApprovalWorkflow,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.currencies,
    component: Currencies,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.currencyExchanges,
    component: CurrencyExchanges,
    exact: true
  }
]

export const attendanceMenus: MenuItem[] = [
  {
    value: 'Locations',
    path: SETTINGS_ROUTES.locations,
    sysPermissionId: Permission.location
  },
  {
    value: 'Breaks',
    path: SETTINGS_ROUTES.breaks,
    sysPermissionId: Permission.break
  },
  {
    value: 'Overtime fields',
    path: SETTINGS_ROUTES.otConfigs,
    sysPermissionId: Permission.otConfig
  },
  {
    value: 'Time elements',
    path: SETTINGS_ROUTES.teConfigs,
    sysPermissionId: Permission.teConfig
  },
  {
    value: 'Shift categories',
    path: SETTINGS_ROUTES.shiftCategories,
    sysPermissionId: Permission.shiftCategory
  },
  {
    value: 'Shift roles',
    path: SETTINGS_ROUTES.shiftRoles,
    sysPermissionId: Permission.shiftRole
  },
  {
    value: 'Shifts',
    path: SETTINGS_ROUTES.shifts,
    sysPermissionId: Permission.shift
  },
  {
    value: 'Work calendars',
    path: SETTINGS_ROUTES.workCalendars,
    sysPermissionId: Permission.calendar
  },
  {
    value: 'Daily policies',
    path: SETTINGS_ROUTES.dailyPolicies,
    sysPermissionId: Permission.dailyPolicies,
    internal: true
  }
]

export const attendanceRoutes: RouteProps[] = [
  {
    path: SETTINGS_ROUTES.locations,
    component: Locations,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.breaks,
    component: Breaks,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.otConfigs,
    component: OtConfigs,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.teConfigs,
    component: TeConfigs,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.shiftCategories,
    component: ShiftCategories,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.shiftRoles,
    component: ShiftRoles,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.shiftRole,
    component: ShiftRole,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.shifts,
    component: Shifts,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.shift,
    component: Shift,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.workCalendars,
    component: Calendars,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.workCalendar,
    component: Calendar,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.dailyPolicies,
    component: DailyPolicy,
    exact: true
  }
]

export const iamMenus: MenuItem[] = [
  {
    value: 'Users',
    path: SETTINGS_ROUTES.logins,
    sysPermissionId: Permission.login
  }
]

export const iamRoutes: RouteProps[] = [
  {
    path: SETTINGS_ROUTES.logins,
    component: Logins,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.login,
    component: Login,
    exact: true
  }
]

export const appConfigsMenus: MenuItem[] = [
  {
    value: 'Employment fields',
    path: SETTINGS_ROUTES.employmentConfigs,
    sysPermissionId: Permission.employmentConfig
  },
  {
    value: 'Self-service access',
    path: SETTINGS_ROUTES.ssAccessConfigs,
    sysPermissionId: Permission.ssAccessConfig
  },
  {
    value: 'Employee self-update',
    path: SETTINGS_ROUTES.selfUpdateConfigs,
    sysPermissionId: Permission.selfUpdateConfig
  },
  {
    value: 'Security',
    path: SETTINGS_ROUTES.idpConfigs,
    sysPermissionId: Permission.idpConfig
  }
]

export const appConfigsRoutes: RouteProps[] = [
  {
    path: SETTINGS_ROUTES.employmentConfigs,
    component: EmploymentConfigs,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.ssAccessConfigs,
    component: SsAccessConfigs,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.selfUpdateConfigs,
    component: SelfUpdateConfigs,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.idpConfigs,
    component: SecurityConfigs,
    exact: true
  }
]

export const otherMenus: MenuItem[] = [
  {
    value: 'Delegations',
    path: SETTINGS_ROUTES.delegations,
    sysPermissionId: Permission.delegation
  },
  {
    value: 'Email groups',
    path: SETTINGS_ROUTES.emailGroups,
    sysPermissionId: Permission.emailGroup
  }
]

export const otherRoutes: RouteProps[] = [
  {
    path: SETTINGS_ROUTES.delegations,
    component: Delegations,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.emailGroups,
    component: EmailGroup,
    exact: true
  }
]

export const integrationMenus: MenuItem[] = [
  {
    value: 'Xero',
    path: SETTINGS_ROUTES.xero,
    sysPermissionId: Permission.xero
  }
]

export const integrationRoutes: RouteProps[] = [
  {
    path: SETTINGS_ROUTES.xero,
    component: Xero,
    exact: true
  },
  {
    path: SETTINGS_ROUTES.xeroCallback,
    component: XeroCallback,
    exact: true
  }
]
