import React, { FC, useCallback, useMemo, useState } from 'react'
import { Button, Card, ColumnsType, Link, PageHeader, Table, Tag } from '~/core-components'
import { DocumentTitle, Person } from '~/components'
import { EMP_ROUTES, SETTINGS_ROUTES } from '~/routes/routes'
import { usePermissionGate } from '~/features/iam'
import { Delimiter, Permission, PermissionAction } from '~/constants'
import { DelegationState } from '../../types'
import { formatDateRangeText, getBaseUrl } from '~/utils'
import { useDelegations } from '../../hooks'
import { useSysOptions } from '~/features/employee/hooks'
import { MutateDelegationDrawer, ROLE_ACTION } from './components/MutateDelegationDrawer'
import './Delegations.less'

interface DelegationsProps {}

type DelegationTable = DelegationState

interface DrawerState {
  visible: boolean
  data?: DelegationState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const baseUrl = getBaseUrl('/filestore')

export const Delegations: FC<DelegationsProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.delegation, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)
  const [delegations, loading] = useDelegations()
  const [delegationRoles] = useSysOptions('delegation_role')

  const routes = useMemo(
    () => [
      {
        path: SETTINGS_ROUTES.main,
        breadcrumbName: 'Settings'
      },
      {
        path: '',
        breadcrumbName: 'Overview'
      }
    ],
    []
  )

  const handleAddDelegation = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditDelegation = useCallback((delegation: DelegationState) => {
    setDrawerState({ visible: true, data: delegation })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns = useMemo(() => {
    let columns: ColumnsType<DelegationTable> = []

    columns.push(
      {
        title: 'Employee',
        key: 'employeeId',
        dataIndex: 'employeeId',
        width: 300,
        render: (value: string, record: DelegationState) => (
          <Person
            name={record.employeeName}
            description={record.description}
            photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/36`}
            path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
          />
        )
      },
      {
        title: 'Delegate to',
        key: 'delegatees',
        dataIndex: 'delegatees',
        render: (value: string[]) => <div style={{ whiteSpace: 'pre' }}>{value.join('\n')}</div>
      },
      {
        title: 'Period',
        key: 'startDate',
        dataIndex: 'startDate',
        width: 200,
        render: (_, record: DelegationState) => formatDateRangeText(record.startDate, record.endDate)
      },
      {
        title: 'Role',
        key: 'role',
        dataIndex: 'role',
        render: (value: string) => delegationRoles[value]?.value
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex: 'action',
        render: (value: string, record: DelegationState) => {
          const actions = ROLE_ACTION.filter(
            r => r.role === record.role && value.split(Delimiter.pipe).includes(r.action)
          )
          return actions.map(act => <Tag type="white">{act.label}</Tag>)
        }
      },
      {
        key: 'action',
        align: 'right',
        width: 70,
        render: (value: string, record) =>
          canModify && (
            <Link size="small" onClick={() => handleEditDelegation(record)}>
              edit
            </Link>
          )
      }
    )

    return columns
  }, [canModify, canViewEmployee, delegationRoles, handleEditDelegation])

  return (
    <div className="delegations">
      <DocumentTitle title="Delegation" />
      <PageHeader
        title="Delegation"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddDelegation}>
              Add delegation
            </Button>
          )
        }
      />
      <div className="delegations__body">
        <Card table>
          <Table rowKey="id" dataSource={delegations} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <MutateDelegationDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
