import React, { FC, useState, useCallback, useEffect, useMemo } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, DrawerForm, EmSelect, Row, SysOptions } from '~/components'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { DelegationRole, Delimiter } from '~/constants'
import { apiGetEmSelect } from '~/features/employee/api/employee.api'
import { ActionResult, Errors } from '~/types/store'
import { DelegationState, IDelegation } from '~/features/master/types'
import { deleteDelegation, saveDelegation } from '~/features/master/actions'
import './MutateDelegationDrawer.less'

export interface MutateDelegationDrawerProps {
  visible: boolean
  data?: DelegationState
  onClose: () => void
}

const TODAY = moment().format('YYYY-MM-DD')
const EMPTY_FORM_DATA: IDelegation = {
  id: '',
  employeeId: '',
  delegateTo: '',
  startDate: TODAY,
  endDate: '',
  role: DelegationRole.ReportingManager,
  action: ''
}

type RoleAction = { group: string; role: string; action: string; label: string }

export const ROLE_ACTION: RoleAction[] = [
  { group: 'leave', role: 'mgr', action: 'view_lve_entitlement', label: 'View leave entitlement' },
  { group: 'leave', role: 'mgr', action: 'view_lve_record', label: 'View leave records' },
  { group: 'leave', role: 'approver', action: 'approve_leave', label: 'Approve leave' },
  { group: 'schedule', role: 'mgr', action: 'plan_schedule', label: 'Plan schedule' }
]

export const MutateDelegationDrawer: FC<MutateDelegationDrawerProps> = ({ visible, data, onClose }) => {
  const [saving, setSaving] = useState(false)
  const [formData, setFormData] = useState<IDelegation>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [actions, setActions] = useState<string[]>([])

  const roleActions = useMemo(() => {
    return ROLE_ACTION.filter(r => r.role === formData.role).reduce((groups, action) => {
      if (!groups[action.group]) {
        groups[action.group] = []
      }

      groups[action.group].push(action)
      return groups
    }, {} as Record<string, typeof ROLE_ACTION>)
  }, [formData.role])

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useDeepCompareEffect(() => {
    if (data) {
      const { id, employeeId, delegateTo, startDate, endDate, role, action } = data

      if (action) {
        const newAction = action.split(Delimiter.pipe)
        setActions(newAction)
      }

      setFormData({
        id,
        employeeId,
        delegateTo,
        startDate,
        endDate,
        role,
        action
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
      setActions([])
    }
  }, [data || {}])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if ('role' in updates) {
      updates.action = ''
      setActions([])
    }

    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setSaving(true)
    try {
      result = await dispatch(saveDelegation(formData))
    } finally {
      setSaving(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (delegation: DelegationState | undefined) => {
      if (delegation) {
        const { id, employeeName } = delegation
        confirm({
          title: 'Delete delegation',
          content: `Do you want to delete this delegation "${employeeName}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteDelegation(id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('active')
    if (status) {
      return result
    }
    return []
  }, [])

  const handleActionCheck = useCallback(
    (e: CheckboxChangeEvent) => {
      const action = e.target.value
      if (e.target.checked) {
        actions.push(action)
      } else {
        actions.splice(actions.indexOf(action), 1)
      }

      handleFormDataChange({ action: actions.join(Delimiter.pipe) })
    },
    [handleFormDataChange, actions]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? `Edit delegation` : `Add delegation`}
      onClose={onClose}
      confirmLoading={saving}
      width={600}
      className="mutate-delegation-drawer"
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-delegation"
    >
      <Form id="form-delegation" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Employee" validateStatus={errors?.employeeId ? 'error' : ''} help={errors?.employeeId}>
              <EmSelect
                ref={focusRef}
                value={formData.employeeId}
                onFetch={handleFetchEmployees}
                onChange={(employeeId: string) => handleFormDataChange({ employeeId })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Delegate to" validateStatus={errors?.delegateTo ? 'error' : ''} help={errors?.delegateTo}>
              <EmSelect
                mode="multiple"
                value={formData.delegateTo ? formData.delegateTo.split(Delimiter.pipe) : undefined}
                onFetch={handleFetchEmployees}
                onChange={(value: string[]) => handleFormDataChange({ delegateTo: value.join(Delimiter.pipe) })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Period"
              validateStatus={errors?.startDate || errors?.endDate ? 'error' : ''}
              help={errors?.startDate || errors?.endDate}
            >
              <Input.DateRange
                value={[
                  formData.startDate ? moment(formData.startDate) : null,
                  formData.endDate ? moment(formData.endDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : ''
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : ''
                  handleFormDataChange({ startDate, endDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Role" validateStatus={errors?.role ? 'error' : ''} help={errors?.role}>
              <SysOptions
                type="delegation_role"
                allowClear={false}
                value={formData.role}
                onChange={(role: string) => handleFormDataChange({ role })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Action(s)"
              className="no-height"
              validateStatus={errors?.action ? 'error' : ''}
              help={errors?.action}
            />
          </Col>
        </Row>
        <Row>
          {Object.entries(roleActions).map(([group, roleActs]) => (
            <Col span={24} key={group}>
              <div className="mutate-delegation-drawer__section">{group}</div>
              <Row gutter={[20, 10]}>
                {roleActs.map(act => (
                  <Col span={24} key={act.action}>
                    <Checkbox value={act.action} onChange={handleActionCheck} checked={actions.includes(act.action)}>
                      {act.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Col>
          ))}
        </Row>
      </Form>
    </DrawerForm>
  )
}
