import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Button, Card, ColumnsType, Form, Space, Table, Tooltip } from '~/core-components'
import { DateCustomPicker, DateCustomPickerItem, Person, SearchInput } from '~/components'
import { useSysOptions } from '~/features/employee'
import { Screen, ViewCriteriaSimple, ViewCriteria, updateViewCriteria, useFirstView } from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { Permission, SsRole } from '~/constants'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { EMP_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { DateRange } from '~/types/common'
import { StoreState } from '~/types/store'
import { showError, getFileTimestamp, formatDateRange, downloadWithDom, getBaseUrl } from '~/utils'
import { refetchROLeaveRecordsView } from '~/features/my/reducers'
import { ROLeaveRecordRowState } from '~/features/my/types'
import { LeaveStatusTag, LeaveTypeName, useLeaveTypesDict } from '~/features/leave'
import { fetchROLeaveRecordsView } from '~/features/my/actions'
import { selectROLeaveRecordsView } from '~/features/my/selectors'
import { apiGetROLeaveRecordExcel } from '~/features/my/api/roleave-record.api'
import './ROLeaveRecord.less'

interface ROLeaveRecordProps {
  role: SsRole
  isActive: boolean
}

const nameColWidth = 250
const paginationStyle: CSSProperties = { marginRight: 20 }

const SCREEN_CODE: Screen = 'ro_leave_record'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const TODAY_YEAR = moment().year()
const START_DATE = moment().startOf('year').format('YYYY-MM-DD')
const END_DATE = moment().endOf('year').format('YYYY-MM-DD')

const PERIOD_OPTIONS: DateCustomPickerItem[] = [
  {
    key: TODAY_YEAR.toString(),
    value: 'This year',
    startDate: START_DATE,
    endDate: END_DATE
  },
  {
    key: (TODAY_YEAR - 1).toString(),
    value: 'Last year',
    startDate: moment().startOf('year').add(-1, 'year').format('YYYY-MM-DD'),
    endDate: moment().endOf('year').add(-1, 'year').format('YYYY-MM-DD')
  }
]

const baseUrl = getBaseUrl('/filestore')

export const ROLeaveRecord: FC<ROLeaveRecordProps> = ({ role, isActive }) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')

  const [leaveTypes] = useLeaveTypesDict()
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const dataLoading = useSelector((state: StoreState) => state.my.roLeaveRecordsViewLoading)
  const data = useSelector(selectROLeaveRecordsView)(viewId)
  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)
  const [leaveUnits] = useSysOptions('lve_unit')
  const canViewEmployee = usePermissionGate(Permission.employee)
  const refetch = useSelector((state: StoreState) => state.my.roLeaveRecordsViewRefetch)

  const [startDate, setStartDate] = useState<string | null>(START_DATE)
  const [endDate, setEndDate] = useState<string | null>(END_DATE)

  useEffect(() => {
    if (viewId && isActive)
      dispatch(
        fetchROLeaveRecordsView(
          viewId,
          role,
          startDate,
          endDate,
          { offset: pageSize * (page - 1), limit: pageSize },
          search
        )
      )
  }, [viewId, role, page, pageSize, search, startDate, endDate, refetch, isActive])

  const handleDateRangeChange = useCallback((startDate: string | null, endDate: string | null) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }, [])

  const tableWidth = nameColWidth + 950

  let columns: ColumnsType<ROLeaveRecordRowState> = [
    {
      title: 'Name',
      key: 'employeeId',
      dataIndex: 'employeeId',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      onCell: (record, index) => {
        const prevId = data.data[(index as number) - 1]?.employeeId
        if (prevId === record.employeeId) {
          return { rowSpan: 0 }
        } else {
          const nextId = data.data[(index as number) + 1]?.employeeId
          if (nextId === record.employeeId) {
            return { rowSpan: record.rowSpan }
          }
        }
        return {}
      },
      render: (_, record) => (
        <Person
          name={record.employeeName}
          description={record.description}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/36`}
          path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
          size={36}
        />
      )
    },
    {
      title: 'Leave type',
      key: 'leaveTypeId',
      dataIndex: 'leaveTypeId',
      width: 180,
      render: (value: string) => <LeaveTypeName id={value} />
    },
    {
      title: 'Date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 200,
      render: (_, record: ROLeaveRecordRowState) => formatDateRange(record.startDate, record.endDate)
    },
    {
      title: 'Duration',
      key: 'leaveTotal',
      dataIndex: 'leaveTotal',
      width: 100,
      align: 'right',
      render: (value: string, record: ROLeaveRecordRowState) =>
        `${value} ${leaveUnits[leaveTypes[record.leaveTypeId]?.unit || '']?.value?.toLowerCase() || ''}`
    },
    {
      title: 'Status',
      key: 'approvalStatuses',
      dataIndex: 'approvalStatuses',
      width: 120,
      render: (value: string[]) => <LeaveStatusTag statuses={value} />
    },
    {
      title: <i className="fal fa-paperclip-vertical att-icon-header" />,
      key: 'attachmentCount',
      dataIndex: 'attachmentCount',
      width: 50,
      render: (value: number) =>
        value > 0 && (
          <>
            <i className="fal fa-paperclip-vertical" />
            <span className="att-count">{value}</span>
          </>
        )
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      width: 300
    }
  ]

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchROLeaveRecordsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleDownloadClick = useCallback(async () => {
    if (viewId) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetROLeaveRecordExcel(viewId, role, {
          ...({ startDate, endDate } as DateRange),
          search
        })

        if (status) {
          const fileName = `leave_records_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, role, search, startDate, endDate, isMountedRef])

  return (
    <div className="roleave-record">
      <div className="roleave-record__body">
        <div className="roleave-record__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            <DateCustomPicker
              defaultValue={TODAY_YEAR.toString()}
              options={PERIOD_OPTIONS}
              onChange={handleDateRangeChange}
            />
            <Tooltip title="Download excel" placement="topRight">
              <Button
                icon={<i className="fal fa-arrow-down-to-bracket" />}
                onClick={handleDownloadClick}
                loading={downloading}
              />
            </Tooltip>
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
    </div>
  )
}
